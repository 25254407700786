import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { CardMedia, Container, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import PageTitle from "./../components/PageTitle";
import * as React from "react";
import MobileNavigation from "./../components/MobileNavigation";
import Navigation from "./../components/Navigation";

import Adoption from "./../images/ourStory/Adoption.jpg";
import Adoption3 from "./../images/ourStory/Adoption3.jpg";
import Adoption4 from "./../images/ourStory/Adoption4.jpg";
import Adoption6 from "./../images/ourStory/Adoption6.jpg";
import Adoption7 from "./../images/ourStory/Adoption7.jpg";
import Adoption8 from "./../images/ourStory/Adoption8.jpg";

import College2 from "./../images/ourStory/College2.jpg";
import DavidGraduation from "./../images/ourStory/DavidGraduation.jpg";
import KatieGraduation from "./../images/ourStory/KatieGraduation.jpg";
import LincolnPark from "./../images/ourStory/LincolnPark.jpg";
import Prom2014 from "./../images/ourStory/Prom2014.jpg";
import Twirp2014 from "./../images/ourStory/Twirp2014.jpg";
import Twirp2015 from "./../images/ourStory/Twirp2015.jpg";
import Homecoming2014 from "./../images/ourStory/Homecoming2014.jpg";
import Prom2015 from "./../images/ourStory/Prom2015.jpg";
import PromiseRing from "./../images/ourStory/PromiseRing.jpg";
import Proposal from "./../images/ourStory/proposal.gif";
import Proposal2 from "./../images/ourStory/proposal2.jpg";
import Proposal3 from "./../images/ourStory/proposal3.jpg";
import Proposal4 from "./../images/ourStory/proposal4.jpg";

import WeMeet from "./../images/ourStory/WeMeet.jpg";
import WeStartDating from "./../images/ourStory/WeStartDating.jpg";
import WeStartDating2 from "./../images/ourStory/WeStartDating2.jpg";
import { Carousel } from "react-material-ui-carousel/dist/components/Carousel";

function Picture(props) {
  return (
    <CardMedia
      image={props.image}
      style={{
        height: "auto",
        maxWidth: "100%",
        width: "auto",
        textAlign: "center",
        boxShadow: "10px 10px 5px #ccc",
        borderRadius: "1%",
      }}
      title={props.image}
      component="img"
    />
  );
}

function Slides(props) {
  const moreThanOnePicture = props.images.length > 1;
  if (moreThanOnePicture) {
    return (
      <Carousel
        navButtonsAlwaysVisible
        autoPlay={false}
        animation="fade"
        duration={300}
        swipe={false}
        // height={props.largeScreen ? 400 : ''}
        indicators={false}
      >
        {props.images.map((pic, i) => (
          <Picture image={pic} key={i} />
        ))}
      </Carousel>
    );
  }
  return <Picture image={props.images[0]} />;
}

function Separator() {
  return (
    <TimelineSeparator>
      <TimelineDot variant="outlined" />
      <TimelineConnector />
    </TimelineSeparator>
  );
}

function TextContent(props) {
  return (
    <>
      <Typography
        variant="h3"
        color="textPrimary"
        fontFamily={"PrestigeSignatureScript"}
        paddingTop="20px"
        paddingBottom="20px"
      >
        {props.date}
      </Typography>
      <Typography variant="h5" color="textPrimary" fontFamily={"Rossesie"}>
        {props.title}
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        fontFamily={"Times New Roman"}
      >
        {props.text}
      </Typography>
    </>
  );
}

function Event(props) {
  if (props.largeScreen) {
    return (
      <TimelineItem>
        <TimelineOppositeContent>
          <TextContent {...props} />
        </TimelineOppositeContent>
        <Separator />
        <TimelineContent>
          <Slides {...props} />
        </TimelineContent>
      </TimelineItem>
    );
  } else {
    return (
      <TimelineItem>
        <Separator />
        <TimelineContent>
          <TextContent {...props} />
          <Slides {...props} />
        </TimelineContent>
      </TimelineItem>
    );
  }
}

export default function OppositeContentTimeline() {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container
      disableGutters
      sx={{
        backgroundColor: "#F5F5F5",
        minWidth: "100%",
      }}
    >
      {largeScreen ? <Navigation /> : <MobileNavigation />}
      <PageTitle text="Our Story" />
      <Timeline
        position={largeScreen ? "alternate" : "right"}
        sx={
          largeScreen
            ? {}
            : {
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }
        }
      >
        <Event
          date="July 27, 2013"
          title="We Meet 👋"
          text="David and Katie meet at a mutual friend's birthday party - Hey Michelle!"
          images={[WeMeet]}
          largeScreen={largeScreen}
        />
        <Event
          date="September 28, 2013"
          title="We Start Dating 💕"
          text="David asks Katie to a Homecoming Dance and takes her to Outback 
          Steakhouse afterwards."
          images={[WeStartDating, WeStartDating2]}
          largeScreen={largeScreen}
        />
        <Event
          date="May 2014"
          title="David's Prom 🕺"
          text="They go to David's senior prom together. That year, they also attend the Valentine's Day dance together."
          images={[Prom2014, Twirp2014]}
          largeScreen={largeScreen}
        />
        <Event
          date="2014"
          title="Promise Ring ⏳"
          text="Before going off to college, David gives Katie a promise ring at the Brookfield Zoo."
          images={[PromiseRing]}
          largeScreen={largeScreen}
        />
        <Event
          date="May 2015"
          title="Katie's Prom 💃"
          text="They go to Katie's senior prom together. David also makes the drive back from college to attend Homecoming and Twirp dances."
          images={[Prom2015, Homecoming2014, Twirp2015]}
          largeScreen={largeScreen}
        />
        <Event
          date="August 2015"
          title="College 🎓"
          text="Katie and David both attend the University of Illinois at Urbana-Champaign."
          images={[College2, DavidGraduation, KatieGraduation]}
          largeScreen={largeScreen}
        />
        <Event
          date="September 2019"
          title="Move In Together 🏡"
          text="After college, Katie and David move in together in Lincoln Park, Chicago."
          images={[LincolnPark]}
          largeScreen={largeScreen}
        />
        <Event
          date="February 2020"
          title="Adopt Lily & Monty 🐕🐕"
          text="A week before the Covid-19 lockdown, they adopt Lily and Monty."
          images={[
            Adoption4,
            Adoption,
            Adoption3,
            Adoption6,
            Adoption7,
            Adoption8,
          ]}
          largeScreen={largeScreen}
        />
        <Event
          date="February 20, 2022"
          title="He Proposed! 💍"
          text="On a private beach in Puerto Rico, David pops the question."
          images={[Proposal, Proposal2, Proposal3, Proposal4]}
          largeScreen={largeScreen}
        />
      </Timeline>
    </Container>
  );
}
