import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import MobileNavigation from "./../components/MobileNavigation";
import Navigation from "./../components/Navigation";
import banner1 from "./../images/home/home.jpeg";

export default function Home() {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        minWidth: "100%",
        height: "100vh",
        backgroundImage: `url(${banner1})`,
        backgroundPosition: "center 50%",
        backgroundSize: "cover",
      }}
    >
      {largeScreen ? <Navigation /> : <MobileNavigation />}
      <Typography
        variant="h1"
        color="white"
        fontFamily={"PrestigeSignatureScript"}
        align="center"
        paddingTop={largeScreen ? "40vh" : "10vh"}
        fontSize={largeScreen ? 150 : 75}
        sx={{
          textShadow: "-1px 1px 2px #000",
        }}
      >
        Thank You!
      </Typography>
    </Container>
  );
}
