import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function LinkTab(props) {
  return (
    <Tab
      sx={{
        fontFamily: "Didot",
        fontSize: 26,
        color: "#0e1111",
        border: 0,
        borderRadius: "5px",
        padding: "6px",
        m: 1,
        "&:hover": {
          border: 1,
          padding: "5px",
        },
      }}
      disableRipple
      component={Link}
      {...props}
    />
  );
}

export default function Navigation() {
  return (
    <Box
      sx={{
        borderBottom: 0,
        borderColor: "divider",
        backgroundImage: "linear-gradient(#f5f5f594, #f5f5f500)",
        // backgroundColor: "#F5f5f5",
        p: 1,
      }}
    >
      <Tabs centered value={false}>
        <LinkTab label="Home" to="/" />
        <LinkTab label="Our Story" to="/our-story" />
        <LinkTab label="Wedding Party" to="/wedding-party" />
        {/* <LinkTab label="Details" to="/details" /> */}
        {/* <LinkTab label="Registry" to="/registry" /> */}
        {/* <LinkTab label="RSVP" to="/rsvp" /> */}
        <LinkTab label="Photos" to="/photos" />
      </Tabs>
    </Box>
  );
}
