import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import MobileNavigation from "./../components/MobileNavigation";
import Navigation from "./../components/Navigation";

export default function Photos() {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        minWidth: "100%",
        height: "100vh",
        backgroundPosition: "center 50%",
        backgroundSize: "cover",
      }}
    >
      {largeScreen ? <Navigation /> : <MobileNavigation />}
      <Typography
        variant="h1"
        color="black"
        fontFamily={"Rossesie"}
        align="center"
        paddingTop={largeScreen ? "40vh" : "10vh"}
        fontSize={largeScreen ? 75 : 30}
        sx={{
          textShadow: "-1px 1px 2px #000",
        }}
      >
        Coming soon!
      </Typography>
    </Container>
  );
}
