import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import WeddingPersonCard from "../components/WeddingPersonCard";

import React from "react";
import MobileNavigation from "./../components/MobileNavigation";
import Navigation from "./../components/Navigation";
import PageTitle from "./../components/PageTitle";
import SubHeader from "./../components/SubHeader";

import DavidAndKatie from "./../images/ourStory/DavidAndKatie.jpeg";
import Olga from "./../images/family/olga.jpeg";
import Vitali from "./../images/family/vitali.jpeg";
import Teresa from "./../images/family/teresa.jpeg";
import Nina from "./../images/family/nina.jpeg";

import Sarah from "./../images/bridesmaids/sarah.jpg";
import Marina from "./../images/bridesmaids/marina.jpg";
import Amanda from "./../images/bridesmaids/amanda.jpg";
import Alesia from "./../images/bridesmaids/alesia.jpg";
import Emily from "./../images/bridesmaids/emily.jpg";
import Spencer from "./../images/groomsmen/spencer.jpg";
import Mihir from "./../images/groomsmen/mihir.jpg";
import Mike from "./../images/groomsmen/mike.jpg";
import Piotr from "./../images/groomsmen/piotr.jpg";
import Dan from "./../images/groomsmen/dan.jpg";
import Nick from "./../images/groomsmen/nick.jpg";
import Shailpik from "./../images/groomsmen/shailpik.jpeg";

import Monty from "./../images/groomsmen/monty.jpeg";
import Charlie from "./../images/groomsmen/charlie.jpg";

function BoxDiv(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {props.children}
    </Box>
  );
}

export default function WeddingParty() {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container
      disableGutters
      sx={{
        backgroundColor: "#F5F5F5",
        minWidth: "100%",
      }}
    >
      {largeScreen ? <Navigation /> : <MobileNavigation />}

      <PageTitle text="Wedding Party" />

      <BoxDiv>
        <WeddingPersonCard
          source={DavidAndKatie}
          name="David and Katie"
          title="The Happy Couple"
        />
      </BoxDiv>

      <SubHeader text="Our Family" />

      <BoxDiv>
        <WeddingPersonCard
          source={Olga}
          name="Olga"
          title="Mother of the Bride"
        />
        <WeddingPersonCard
          source={Vitali}
          name="Vitali"
          title="Father of the Bride"
        />
        <WeddingPersonCard
          source={Teresa}
          name="Teresa"
          title="Mother of the Groom"
        />
        <WeddingPersonCard
          source={Nina}
          name="Nina"
          title="Grandmother of the Bride"
        />
      </BoxDiv>

      <SubHeader text="The Wedding Party" />

      <BoxDiv>
        <WeddingPersonCard source={Sarah} name="Sarah" title="Maid of Honor" />
        <WeddingPersonCard source={Dan} name="Dan" title="Best Man" />
      </BoxDiv>

      <BoxDiv>
        <WeddingPersonCard source={Marina} name="Marina" title="Bridesmaid" />
        <WeddingPersonCard source={Nick} name="Nick" title="Groomsman" />

        <WeddingPersonCard source={Amanda} name="Amanda" title="Bridesmaid" />
        <WeddingPersonCard source={Spencer} name="Spencer" title="Groomsman" />

        <WeddingPersonCard source={Alesia} name="Alesia" title="Bridesmaid" />
        <WeddingPersonCard source={Mike} name="Mike" title="Groomsman" />

        <WeddingPersonCard source={Emily} name="Emily" title="Bridesmaid" />
        <WeddingPersonCard source={Mihir} name="Mihir" title="Groomsman" />

        <WeddingPersonCard source={Piotr} name="Piotr" title="Groomsman" />
        <WeddingPersonCard
          source={Shailpik}
          name="Shailpik"
          title="Groomsman"
        />
      </BoxDiv>

      <SubHeader text="Celebrating from Home" />
      <BoxDiv>
        <WeddingPersonCard
          source={Monty}
          name="Monty"
          title="Best Dog / Dog of Honor"
        />
        <WeddingPersonCard source={Charlie} name="Charlie" title="Groomsdog" />
      </BoxDiv>
    </Container>
  );
}
