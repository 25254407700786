import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import Divider from "@mui/material/Divider";

export default function PageTitle(props) {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Typography
        variant={largeScreen ? "h2" : "h3"}
        color="#313639"
        fontFamily={"Rossesie"}
        padding={largeScreen ? "9vh 0 8vh 0" : "90px 0 0 0"}
        align="center"
      >
        {props.text}
      </Typography>
      <Divider variant="inset" sx={{ marginBottom: "50px" }} />
    </>
  );
}
