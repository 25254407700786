import CloseIcon from "@mui/icons-material/Close";
import MenuOutlined from "@mui/icons-material/MenuOutlined";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function NavItem(props) {
  return (
    <ListItemButton component={Link} to={props.to}>
      <ListItemText
        disableTypography
        primary={props.name}
        sx={{ fontFamily: "Rossesie", fontSize: "20px" }}
      />
    </ListItemButton>
  );
}

export default function MobileNavigation() {
  const [open, setState] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  return (
    <Container
      align="right"
      sx={{
        zIndex: "100",
        position: "fixed",
      }}
    >
      <IconButton component="label" onClick={toggleDrawer(true)}>
        <MenuOutlined
          sx={{
            fontSize: 50,
            color: "#0e1111",
            backgroundColor: "#F5F5F5",
            borderRadius: 20,
            padding: "10px",
          }}
        />
      </IconButton>

      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            p: 2,
            backgroundColor: "#F5F5F5",
            height: "100vh",
          }}
        >
          <IconButton onClick={toggleDrawer(false)} sx={{ mb: 2 }}>
            <CloseIcon />
          </IconButton>

          <Box sx={{ mb: 2 }}>
            <NavItem to="/" name="Home" />
            <NavItem to="/our-story" name="Our Story" />
            <NavItem to="/wedding-party" name="Wedding Party" />
            <NavItem to="/details" name="Details" />
            <NavItem to="/registry" name="Registry" />
            <NavItem to="/rsvp" name="RSVP" />
          </Box>
        </Box>
      </Drawer>
    </Container>
  );
}
