import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";


export default function PageTitle(props) {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Typography
      variant={largeScreen ? "h3" : "h4"}
      color="#212427"
      fontFamily={"Rossesie"}
      padding={largeScreen ? "9vh 0" : "10px 0"}
      align="center"
    >
      {props.text}
    </Typography>
  );
}
