// import Navigation from "./components/Navigation";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import "./index.css";
import Home from "./pages/Home";
import OurStory from "./pages/OurStory";
import WeddingParty from "./pages/WeddingParty";
import Details from "./pages/Details";
// import Registry from "./pages/Registry";
// import RSVP from "./pages/RsvpForm";
import ScrollToTop from "./components/ScrollToTop";
import Photos from "./pages/Photos";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00000099",
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/wedding-party" element={<WeddingParty />} />
          <Route path="/details" element={<Details />} />
          {/* <Route path="/registry" element={<Registry />} /> */}
          {/* <Route path="/rsvp" element={<RSVP />} /> */}
          <Route path="/photos" element={<Photos />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
