import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import CardMedia from "@mui/material/CardMedia/CardMedia";
import PageTitle from "./../components/PageTitle";
import React from "react";
import MobileNavigation from "./../components/MobileNavigation";
import Navigation from "./../components/Navigation";

import Parking from "./../images/details/parking.jpg";
import Divider from "@mui/material/Divider";

function Header(props) {
  return (
    <Typography
      variant="h3"
      color="textPrimary"
      fontFamily={"Rossesie"}
      paddingTop="20px"
      paddingBottom="20px"
    >
      {props.title}
    </Typography>
  );
}

function Line() {
  return <Divider variant="inset" sx={{ margin: "50px 0" }} />;
}

function SubTitle(props) {
  return (
    <Typography
      variant="h4"
      color="textPrimary"
      fontFamily={"Didot"}
      // fontSize={"50px"}
    >
      {props.text}
    </Typography>
  );
}

function Text(props) {
  return (
    <Typography variant="body1" fontFamily="Times New Roman" fontSize={"20px"}>
      {props.text}
    </Typography>
  );
}

export default function Details() {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Container
      disableGutters
      sx={{
        textAlign: "center",
        backgroundColor: "#F5F5F5",
        minWidth: "100%",
        minHeight: "100vh",
      }}
    >
      {largeScreen ? <Navigation /> : <MobileNavigation />}
      <PageTitle text="Details" />
      <Container>
        <Header title="When" />
        <Container sx={{ marginTop: "20px", marginBottom: "20px" }}>
          <SubTitle text="Sunday, July 2 2023" />
          <Text text="Ceremony to begin at 3pm" />
          <Text text="Cocktail hour and reception to immediately follow" />
          <Text text="End time: 10pm" />
        </Container>
        <Line />
      </Container>

      <Container>
        <Header title="Where" />
        <Container sx={{ marginTop: "20px", marginBottom: "20px" }}>
          <SubTitle text="Loft Lucia" />
          <Text text="7 N Carpenter St, Chicago, IL 60607" />
          <Text text="Located on top of the Monteverde restaurant" />
          <Text text="Entrance is on the N Carpenter St side" />
        </Container>
        <Line />
      </Container>

      <Container>
        <Header title="What to Wear" />
        <Container sx={{ marginTop: "20px", marginBottom: "20px" }}>
          <SubTitle text="Formal Attire" />
          <Text text="We encourage you to dress up!" />
          <Text text="Suits, ties, gowns, and dresses are all welcome!" />
          <Text text="Please refrain from wearing white and green (reserved for the wedding party)" />
        </Container>
        <Line />
      </Container>

      <Container>
        <Header title="Parking" />
        <Container sx={{ marginTop: "20px", marginBottom: "20px" }}>
          <SubTitle text="1104 W Monroe St Parking Lot" />
          <Text
            text="We have reserved the Kolcraft parking lot located across the street from our apartment. 
              "
          />
          <Text text="Parking will be free and available from Sunday morning until Monday evening." />
          <CardMedia
            image={Parking}
            title="Parking"
            component="img"
            sx={{
              maxWidth: "500px",
              margin: "auto",
              border: "1px solid #555",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          />
        </Container>
        <Line />
      </Container>

      <Container>
        <Header title="Hotels" />
        <Container sx={{ marginTop: "20px", paddingBottom: "100px" }}>
          <SubTitle text="Dealer's Choice" />
          <Text
            text="We decided against booking a hotel block as the pricing near the venue is 
            quite high for the holiday weekend. We encourage you to book the hotel of your choice
            in the Chicagoland area and simply Uber to the venue to save some cash.
            If you're interested in sharing a room or an Airbnb with other travelers,
            let us know and we'll find you a roommate!"
          />
        </Container>
      </Container>
    </Container>
  );
}
