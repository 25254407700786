import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";

export default function WeddingPersonCard(props) {
  return (
    <Card
      raised
      sx={{
        borderRadius: "20px",
        backgroundColor: "#faf0e6",
        width: props.width || "300px",
        margin: "20px",
      }}
    >
      <Box>
        <CardMedia image={props.source} title={props.name} component="img" />
      </Box>
      <CardContent>
        <Typography
          variant="h4"
          component="div"
          sx={{ fontFamily: "GranceDemo", fontSize: 24, textAlign: "center" }}
        >
          {props.title}
        </Typography>

        <br></br>

        <Typography
          gutterBottom
          variant="h5"
          sx={{
            fontFamily: "PrestigeSignatureScript",
            fontSize: 40,
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {props.name}
        </Typography>
      </CardContent>
    </Card>
  );
}
